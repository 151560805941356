body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #D1CDC7; /* dark color */
    background: #181A1B; /* dark bg */
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.no-decoration {
    text-decoration: none;
    color: rgba(232, 230, 227, 0.55);
}

.theme-bg {
    /* background-color: #181A1B; */
    background-color: rgb(24, 26, 27);
    border-color: rgba(140, 130, 115, 0.5);
}

.theme-bg-light {
    background-color: #1B1E1F;
    border: 1px solid rgba(140, 130, 115, 0.5);
}

.card {
    border: 1px solid rgba(140, 130, 115, 0.5);
}

.card-header {
    background-color: #181A1B;
    border-bottom-color: rgba(140, 130, 115, 0.5);
}

.card-body {
    background: #181A1B;
}

.form-select {
    color: rgb(209, 205, 199);
    background: #181A1B;
    border-color: rgb(4, 106, 180);
}

.form-control {
    color: rgb(209, 205, 199);
    background-color: rgb(24, 26, 27);
    border-color: rgb(4, 106, 180);
}

.form-control:focus {
    color: rgb(209, 205, 199);
    background-color: rgb(24, 26, 27);
    border-color: rgb(209, 205, 199);
}

.navbar-light .navbar-brand {
    color: rgba(232, 230, 227, 0.9);
}

.navbar-light .navbar-nav .nav-link {
    color: rgba(232, 230, 227, 0.55);
}

.list-group-item {
    color: rgb(209, 205, 199);
    text-decoration-color: initial;
    background-color: rgb(24, 26, 27);
    border-color: rgb(4, 106, 180);
}

.table {
    color: rgb(209, 205, 199);
    border-color: rgba(140, 130, 115, 0.5);
    width: 100%;
    word-wrap: break-word;
    font-size: 0.800em;
}

.table-striped > tbody > tr:nth-of-type(2n+1) {
    color: #d1cdc7;
}

.table-striped > tbody > tr:nth-of-type(2n) {
    color: #d1cdc7;
}

.list-group-item-action:active {
    color: rgb(209, 205, 199);
    background-color: rgb(35, 38, 39);
}

.list-group-item-action:focus, .list-group-item-action:hover {
    color: rgb(181, 175, 166);
    text-decoration-color: initial;
    background-color: rgb(27, 30, 31);
}

.form-control:disabled, .form-control[readonly] {
    background-color: rgb(35, 38, 39);
}

.alert-success {
    color: rgb(202, 197, 190);
    background-color: rgb(32, 61, 51);
    border-color: rgba(140, 130, 115, 0.5);
}

.required {
    color: red;
}

.navbar-light .navbar-brand:focus, .navbar-light .navbar-brand:hover {
    color: rgba(232, 230, 227, 0.9) !important;
}

.navbar-light .navbar-nav .nav-link:focus, .navbar-light .navbar-nav .nav-link:hover {
    color: rgba(232, 230, 227, 0.9) !important;
}

.input-group-text {
    color: rgb(209, 205, 199);
    background-color: rgb(35, 38, 39);
    border-color: rgb(4, 106, 180);
}

.navbar-toggler {
    background-color: rgba(140, 130, 115, 0.5);
}

.mycluster {
    width: 40px;
    height: 40px;
    background-color: greenyellow;
    text-align: center;
    font-size: 24px;
}

.custom-marker-cluster {
    background: #ffffff;
    border: 15px solid #f00800;
    border-radius: 50%;
    color: #000000;
    height: 33px;
    line-height: 37px;
    text-align: center;
    width: 33px;
}
